import React, { Component } from 'react';
import classNames from 'classnames';
import queryString from 'query-string';

import LayoutTextBoxInput from '@designhuddle/dshd-react-ui-kit/dist/LayoutTextBoxInput';

import PasswordManager from './components/PasswordManager';
import Modals from './components/Modals';

import { PopupContext } from './contexts/popup-context';

// import 'dshd-react-ui-kit/dist/index.css';
import './App.css';

let devUrl = '';
if (process.env.NODE_ENV === 'development') {
  devUrl = 'http://0.0.0.0:3001/https://liveswap.dshddev.com';
}

class App extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorMsg: '',
      signingIn: false,
      forgotPasswordVisible: false,
      passwordSentVisible: false,
      errorPopupVisible: false,
      errorPopupMessage: '',
    };

    this.handleEmailChange = this.handleFieldChange.bind(this, 'email');
    this.handlePasswordChange = this.handleFieldChange.bind(this, 'password');
  }

  handleFieldChange(field: string, value: string) {
    this.setState({
      [field]: value,
    });
  }

  handleKeyPress = (value: string) => {
    if (value === 'Enter') {
      this.signIn();
    }
  }

  onForgotYourPassword = (value: boolean) => {
    this.setState({ forgotPasswordVisible: value });
  }
  onPasswordSent = (value: boolean) => {
    this.setState({ passwordSentVisible: value });
  }
  openErrorPopup = (value: boolean, message: string = '') => {
    this.setState({
      errorPopupVisible: value,
      errorPopupMessage: message,
    });
  }

  onError = (data: Object) => {
    if (window.location.search) {
      window.history.replaceState(null, null, window.location.pathname);
    }
    this.setState({ errorMsg: data.message, signingIn: false });
  }

  signIn = () => {
    if (this.state.signingIn) return;
    this.setState({ signingIn: true });
    // const formData = new FormData();
    // formData.append('email_address', this.state.email);
    // formData.append('password', this.state.password);
    const body = new URLSearchParams();
    body.append('email_address', this.state.email);
    body.append('password', this.state.password);
    const headers = new Headers({});
    if (body instanceof URLSearchParams) {
      headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    }
    fetch(`${devUrl}/auth/login`, {
      method: 'POST',
      credentials: 'same-origin',
      headers,
      body,
    })
      .then(response => response.json()).then((data) => {
        if (data.success) {
          let href = data.partner ? '/partners/' : '/app';
          const hrefArr = window.location.href.split('?');
          if (hrefArr.length > 1) {
            hrefArr.shift();
            const queryParsed = queryString.parse(hrefArr[0]);
            // const queryParsed = queryString.parse(hrefArr[0]);
            if (queryParsed.return) {
              if (hrefArr.length > 1) {
                queryParsed.return = `${queryParsed.return}?${hrefArr[1]}`;
              }
              // href = queryParsed.return;
              // if (data.partner) {
              //   if (href.includes('brand') && !href.includes('partners/brand')) { // '/partners/brand#'
              //     href = href.replace('/brand', '/partners/brand');
              //   }
              //   href = href.replace('/app', '/partners/');
              // } else {
              //   if (href.includes('brand')) { // '/brand#'
              //     href = href.replace('/partners/brand', '/brand');
              //   }
              //   href = href.replace('/partners/', '/app');
              // }
              if (data.partner && queryParsed.return.includes('/partners')) {
                href = queryParsed.return;
              }
              if ((!data.partner) && (!queryParsed.return.includes('/partners'))) {
                href = queryParsed.return;
              }
            }
          }
          window.location.href = href;
        } else {
          this.onError(data);
        }
      })
      .catch((error) => {
        this.onError(error);
      });
    return false;
  }

  render() {
    const parsed = queryString.parse(window.location.search);
    if (parsed.w || parsed.pr) {
      return (
        <div className="App">
          <PasswordManager
            queryString={parsed}
            onError={this.onError}
          />
        </div>
      );
    }
    return (
      <div className="App">
        <div className="login">
          <div className="logo">
            <img
              src={`${window.DSHD.large_logo_url}`}
              alt="large_logo_url"
            />
          </div>
          <form className="login-form" autoComplete="on">
            <LayoutTextBoxInput
              title="Email:"
              placeholder=""
              value={this.state.email}
              onChange={this.handleEmailChange}
              handleKeyUp={this.handleEmailChange}
              type="email"
              name="email"
              autoComplete="email"
              maxLength={80}
            />
            <LayoutTextBoxInput
              title="Password:"
              placeholder=""
              value={this.state.password}
              onChange={this.handlePasswordChange}
              handleKeyUp={this.handlePasswordChange}
              handleKeyPress={this.handleKeyPress}
              type="password"
              name="password"
              autoComplete="current-password"
              maxLength={20}
            />
          </form>
          <div className="options">
            <button onClick={this.onForgotYourPassword.bind(this, true)}>
              Forgot your password?
            </button>
          </div>
          <button
            className={classNames('action-btn', {
              disabled: this.state.signingIn,
            })}
            onClick={this.signIn}
          >
            Sign In
          </button>
          {this.state.errorMsg && (
            <div className="error-msg">
              {this.state.errorMsg}
            </div>
          )}
        </div>
        <PopupContext.Provider value={{
          popupForgotPassword: {
            email: this.state.email,
            visible: this.state.forgotPasswordVisible,
            onClose: this.onForgotYourPassword.bind(this, false),
            onSuccess: this.onPasswordSent.bind(this, true),
            onError: this.openErrorPopup.bind(this, true),
          },
          popupPasswordSent: {
            visible: this.state.passwordSentVisible,
            onClose: this.onPasswordSent.bind(this, false),
          },
          popupError: {
            message: this.state.errorPopupMessage,
            visible: this.state.errorPopupVisible,
            onClose: this.openErrorPopup.bind(this, false),
          },
        }}>
          <Modals />
        </PopupContext.Provider>
      </div>
    );
  }
}

export default App;
