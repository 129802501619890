/* @flow */

import React from 'react';
import PopupModal from '@designhuddle/dshd-react-ui-kit/dist/PopupModal';

export default function PopupPasswordSent(props: Object) {
  const onCancel = () => {
    props.onClose && props.onClose();
  };

  return (
    <PopupModal
      visible={props.visible}
      contentLabel="Password Sent"
      popupClassName="popup-password-sent"
      title="Password Sent"
      titleP="Please check your email for your password reset link. It will only function for the next 10 minutes."
      onCancel={onCancel}
    />
  );
}
