/* @flow */

import React from 'react';
import PopupModal from '@designhuddle/dshd-react-ui-kit/dist/PopupModal';

export default function PopupError(props: Object) {
  const onCancel = () => {
    props.onClose && props.onClose();
  };

  return (
    <PopupModal
      visible={props.visible}
      contentLabel="Error"
      popupClassName="popup-error"
      title="Error"
      titleP={props.message}
      onCancel={onCancel}
    />
  );
}
