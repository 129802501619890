/* @flow */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PopupModal from '@designhuddle/dshd-react-ui-kit/dist/PopupModal';
import LayoutTextBoxInput from '@designhuddle/dshd-react-ui-kit/dist/LayoutTextBoxInput';

let devUrl = '';
if (process.env.NODE_ENV === 'development') {
  devUrl = 'http://0.0.0.0:3001/https://liveswap.dshddev.com';
}

type Props = {
  visible: boolean,
  onCancel: Function,
  email: string,
};
type State = {
  email: string,
};
export default class PopupForgotPassword extends Component<Props, State> {
  static propTypes = {
    onClose: PropTypes.func,
  };

  constructor(props: Object) {
    super(props);
    this.state = {
      email: '',
      callInProcess: false,
    };
    this.onCancel = this.onCancel.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setEmailFinish = this.setEmailFinish.bind(this);
    this.sendForgotPasswordEmail = this.sendForgotPasswordEmail.bind(this);
  }

  componentDidUpdate(prevProps: Object) {
    if (prevProps.visible !== this.props.visible) {
      this.setState({ email: this.props.email || '' });
    }
  }

  setEmail: Function;
  setEmail(value: string) {
    this.setState({ email: value });
  }

  setEmailFinish: Function;
  setEmailFinish(value: string, lastKey: any) {
    this.setState({ email: value });
    if (lastKey === 'Enter') {
      this.sendForgotPasswordEmail();
    }
  }

  sendForgotPasswordEmail: Function;
  sendForgotPasswordEmail() {
    if (this.state.callInProcess) return;
    this.setState({ callInProcess: true });
    const body = new URLSearchParams();
    body.append('email_address', this.state.email);
    const headers = new Headers({});
    if (body instanceof URLSearchParams) {
      headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    }
    fetch(`${devUrl}/auth/sendpasswordreset`, {
      method: 'POST',
      credentials: 'same-origin',
      headers,
      body,
    })
      .then(response => response.json())
      .then((data) => {
        if (data.success) {
          this.onCancel();
          this.props.onSuccess && this.props.onSuccess();
        } else {
          this.onCancel();
          this.props.onError && this.props.onError(data.message || 'There is a problem with this service.');
        }
      })
      .catch((error) => {
        this.onCancel();
        this.props.onError && this.props.onError(error.message || 'There is a problem with this service.');
      })
      .finally(() => {
        this.setState({ callInProcess: false });
      });
  }

  onCancel: Function;
  onCancel() {
    this.props.onClose && this.props.onClose();
  }

  render() {
    return (
      <PopupModal
        visible={this.props.visible}
        contentLabel="Forgot Password"
        popupClassName="popup-forgot-password"
        title="Forgot Password"
        titleP="Please enter your email address to receive a password reset link."
        onCancel={this.onCancel}
      >
        <LayoutTextBoxInput
          id="PopupEmailInput"
          className="email"
          title="Email:"
          value={this.state.email}
          onChange={this.setEmailFinish}
          handleKeyUp={this.setEmail}
        />
        <div className="center center-btn">
          <button
            className="btn btn-gray"
            onClick={this.onCancel}
          >
            Cancel
          </button>
          <button
            className={classNames('btn', {
              disabled: this.state.callInProcess || !this.state.email,
            })}
            onClick={this.sendForgotPasswordEmail}
          >
            Reset Password
          </button>
        </div>
      </PopupModal>
    );
  }
}
